<template>
  <span>
    <slot v-if="$checkPermission(role, permission)" />
    <div class="flix-alert flix-alert-danger" v-else-if="error">
      keine Zugriffsberechtigung
    </div>
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    role: {
      type: String,
      default () { return 3 }
    },
    permission: {
      type: [Number, String],
      default () { return 0 }
    },
    error: {
      type: Boolean,
      default () { return false }
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
